import React from "react";
import { createRoot } from "react-dom/client";
import {
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { Buffer } from "buffer";
import process from "process";

import "./index.css";
import App from "./App";

// Polyfills
window.Buffer = Buffer;
window.process = process;

require("@solana/wallet-adapter-react-ui/styles.css");

// Set the Phantom wallet adapter
const wallets = [new PhantomWalletAdapter()];

// Use the direct Helius RPC endpoint (DO NOT use clusterApiUrl)
const endpoint =
  "https://mainnet.helius-rpc.com/?api-key=a45418f9-ddf2-4bdb-8462-729ac3e0be25";

// Set up the root render
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <App />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  </React.StrictMode>
);
