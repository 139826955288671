import React, { useState, useEffect } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import axios from "axios";
import "./App.css";
import Leaderboard from "./Leaderboard";

function App() {
  const [counter, setCounter] = useState(0);
  const [increment, setIncrement] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [solPrice, setSolPrice] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [userName, setUserName] = useState("");
  const [showOnLeaderboard, setShowOnLeaderboard] = useState(false);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  useEffect(() => {
    const fetchSolPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd"
        );
        setSolPrice(response.data.solana.usd);
      } catch (error) {
        console.error("Error fetching SOL price:", error);
        setSolPrice(0);
      }
    };

    fetchSolPrice();
    const interval = setInterval(fetchSolPrice, 60000); // Update price every minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (publicKey) {
      const walletAddress = publicKey.toString();
      const storedData = JSON.parse(
        localStorage.getItem(walletAddress) ||
          '{"clickCount": 0, "totalPaid": 0, "userName": "", "showOnLeaderboard": false}'
      );
      setClickCount(storedData.clickCount);
      setTotalPaid(storedData.totalPaid);
      setUserName(storedData.userName || "");
      setShowOnLeaderboard(storedData.showOnLeaderboard || false);
    }
  }, [publicKey]);

  const updateLocalStorage = (newClickCount, newTotalPaid) => {
    if (publicKey) {
      const walletAddress = publicKey.toString();
      localStorage.setItem(
        walletAddress,
        JSON.stringify({
          clickCount: newClickCount,
          totalPaid: newTotalPaid,
          userName: userName,
          showOnLeaderboard: showOnLeaderboard,
        })
      );
    }
  };

  const handleClick = () => {
    setCounter((prevCounter) => prevCounter + Number(increment));
    const newClickCount = clickCount + 1;
    setClickCount(newClickCount);
    updateLocalStorage(newClickCount, totalPaid);
  };

  const handleReset = () => {
    setCounter(0);
  };

  const handleIncrementChange = (event) => {
    const value = event.target.value;
    if (value === "" || parseInt(value) > 0) {
      setIncrement(value);
    }
  };

  const handlePay = async () => {
    if (!publicKey) {
      alert("Please connect your wallet");
      return;
    }

    setIsLoading(true);
    try {
      const solAmount = counter / solPrice;

      const lamports = Math.round(solAmount * LAMPORTS_PER_SOL);

      const recipientPubKey = new PublicKey(
        "C8JuNgKYAdqgpRNV6r7WbSKGRkUH3pumSBZyVP7dhKKS"
      );

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: recipientPubKey,
          lamports: lamports,
        })
      );

      console.log("transaction-->", transaction);

      const signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, "processed");

      const newTotalPaid = totalPaid + counter;
      setTotalPaid(newTotalPaid);
      updateLocalStorage(clickCount, newTotalPaid);

      alert(`Payment of $${counter} (${solAmount.toFixed(4)} SOL) successful!`);
      setCounter(0);
    } catch (error) {
      console.error("Error:", error);
      alert("Payment failed. See console for details.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
    updateLocalStorage(clickCount, totalPaid);
  };

  const handleShowOnLeaderboardChange = (event) => {
    setShowOnLeaderboard(event.target.checked);
    updateLocalStorage(clickCount, totalPaid);
  };

  return (
    <div className="App">
      <div className="counter">${counter}</div>
      <div className="sol-price">1 SOL = ${solPrice.toFixed(2)} USD</div>
      <div className="increment-control">
        <span>Increment: $</span>
        <input
          type="number"
          value={increment}
          onChange={handleIncrementChange}
          min="1"
        />
      </div>
      <div className="buttons">
        <button
          className="pay-button"
          onClick={handlePay}
          disabled={isLoading || solPrice === 0}
        >
          {isLoading ? "Processing..." : "Pay"}
        </button>
        <button className="click-button" onClick={handleClick}>
          Click
        </button>
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
      </div>
      {publicKey && (
        <div className="wallet-stats">
          <p>Clicks: {clickCount}</p>
          <p>Total Paid: ${totalPaid.toFixed(2)}</p>
          <input
            type="text"
            placeholder="Enter your name"
            value={userName}
            onChange={handleUserNameChange}
          />
          <label>
            <input
              type="checkbox"
              checked={showOnLeaderboard}
              onChange={handleShowOnLeaderboardChange}
            />
            Show on Leaderboard
          </label>
        </div>
      )}
      <WalletMultiButton />
      {/* <Leaderboard /> */}
    </div>
  );
}

export default App;
